import React, { Component } from 'react'
import styles from './index.module.scss'
import Img from 'Components/UI/Img'
import AppConstants from 'AppConstants'

class FullscreenSlideshow extends Component {
  componentDidMount() {
    window.addEventListener('keydown', this.onKeydown)
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeydown)
  }
  onKeydown = (e) => {
    if (e.key === 'Escape') this.props.onClose()
  }
  render() {
    const slides = this.props.images.map((image, index) => {
      return (
        <div key={`product-image-fullscreen-slideshow-${image.src}-${index}`}>
          <Img
            baseUrl={AppConstants.CLOUDFRONT_CONTENT_URL}
            className={`${styles.mainImage}`}
            id={index}
            src={image.src}
            srcRetina={image.srcRetina}
            noRetinaOnMobile={true}
          />
        </div>
      )
    })
    return (
      <div className={`u-fixed u-fit u-pos-tl u-z-index--9982 u-overflow-y-s`}>
        <div className='u-col-1 u-offset-10'>
          <button className={`${styles.closeButton} u-fixed`} onClick={this.props.onClose}>
            <svg className={`${styles.innerIcon}`}>
              <use xlinkHref={`#cross-icon`} />
            </svg>
          </button>
        </div>
        <div className='u-col-12 u-absolute'>{slides}</div>
        <div onClick={this.props.onClose} className={`u-z-index--back u-fit-vwh u-fixed t-bg-white u-pos-tl`}></div>
      </div>
    )
  }
}

export default FullscreenSlideshow
