import React, { Component } from 'react'
import Tooltip from 'Components/UI/Tooltip'
import styles from './index.module.scss'

class SizesSelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }
  render() {
    const availableSizes = this.props.availableSizes.map((size, i) => {
      const isSelected = this.props.selectedSize && this.props.selectedSize.id === size.id ? true : false
      return (
        <Tooltip
          hidden={!this.props.showTooltip}
          key={`size-variant-${i}`}
          placement={`top`}
          className={`${styles.tooltipWrapper} u-relative u-inline-block`}
          isOpen={isSelected}
          reference={
            <button
              className={`${styles.sizeVariant} ${size.in_stock ? '' : styles.sizeNotAvailable} ${isSelected ? styles.isSelectedSize : ''} u-block u-text-center t-paragraph-0`}
              value={parseInt(size.name)}
              onClick={() => { this.props.onSizeClick(size) }}>
              {size.name}
            </button>
          }
          popper={
            <div>
              { size.in_stock && <div className={`${size.available_items === 1 ? 't-color-red' : '' }`}>{size.available_items} left</div>}
              { !size.in_stock && <div style={{width: 60}}>Sold out</div>}
            </div>
          }
        />
      )
    })
    return (
      <div className={`${styles.parent} ${this.props.className ? this.props.className : ''}`}>
        <p onClick={() => { this.setState({ open: !this.state.open }) }} className='u-inline-block t-title-1 u-uppercase u-pad-y--small'>choose size</p>
        <a href={`/size-guide`} target={`_blank`} className={`${styles.sizeGuide} t-paragraph-0 u-pad-l--20`}>Size guide</a>
        <div className={`${styles.sizes} u-inline-block u-fit-w`}>{ availableSizes }</div>
      </div>
    )
  }
}

SizesSelector.defaultProps = {
  showTooltip: true
}

export default SizesSelector
