import React, { Component } from 'react'
import Form from 'Components/UI/Form'
import SizesSelector from 'Components/UI/SizesSelector'
import BackgroundBtn from 'Components/UI/BackgroundBtn'
import AppStore from 'AppStore'
import find from 'lodash/find'
import { CSSTransition } from 'react-transition-group'
import { productSizes } from 'Components/Utils/helpers'
import styles from './index.module.scss'

class WaitlistPanel extends Component {
  constructor(props) {
    super(props)
    this.form = React.createRef()
  }
  render() {
    const customer = AppStore.getCustomer()
    const sizes = productSizes.map(item => {
      const currentRoute = find(this.props.availableSizes, (o) => { return o.id === item.id })
      const variation_id = currentRoute ? currentRoute.variation_id : ''
      return {
        variation_id,
        in_stock: true,
        ...item
      }
    })
    const formFields = [
      {
        type: 'input',
        props: {
          id: 'mail',
          className: 'u-col-12',
          type: 'email',
          placeholder: 'Enter your mail',
          value: customer ? customer.email : '',
          required: true
        }
      }
    ]
    return (
      <div className={`${styles.waitlistMessage}`}>
        { this.props.isLoading &&
        <CSSTransition
          timeout={600}
          in={true}
          appear={true}
          enter={false}
          exit={false}
          classNames={{
            appear: 'u-fade-appear',
            appearActive: 'u-fade-appear-active--simple'
          }}
        >
          <div className={styles.waitlistLoaderContainer}>
            <img className={`${styles.spinner}`} src={`/images/spinner_white.gif`} />
            <div className={styles.background}></div>
          </div>
        </CSSTransition>
        }
        <div className={`${styles.closeButton} u-btn`} onClick={this.props.closeWaitlistMessagePanel}>
          <svg className={`${styles.innerIcon}`}>
            <use xlinkHref={`#cross-icon`} />
          </svg>
        </div>
        <div className={`${styles.waitlistInner}`}>
          <h2 className={`t-title-2 u-uppercase`}>SIGN UP FOR THE WAITLIST</h2>
          <div className={`u-height--lg--10`} />
          <div className={`u-height--only-lg--10`} />
          <p>Sorry—the demand for this style has been higher than we expected! A new shipment should be arriving soon. If you’d like us to notify you when the shoes arrive, please select your size and join the waitlist, below.</p>
          <div className={`u-height--lg--10`} />
          <div className={`u-height--only-lg--10`} />
          <div className={`${styles.sizeSelectorContainer}`}>
            <SizesSelector
              showTooltip={false}
              availableSizes={sizes}
              selectedSize={this.props.waitlistSelectedSize}
              onSizeClick={this.props.onSizeWaitlistClick}
              onClickSizeGuideBtn={this.props.onSizeGuideClick}
            />
          </div>
          { this.props.showWaitlistSizeErrorMessage &&
            <CSSTransition
              timeout={600}
              in={true}
              appear={true}
              enter={false}
              exit={false}
              classNames={{
                appear: 'u-fade-appear',
                appearActive: 'u-fade-appear-active--simple'
              }}
            >
              <div>
                <div className={`u-height--only-lg--10`} />
                <div className={`u-height--lg--10`} />
                <p className={`t-color-red`}>Please select your size</p>
              </div>
            </CSSTransition>
          }
          <div className={`u-remove-autofill`}>
            <Form items={formFields} submit={this.props.onWaitlistFormSubmit} ref={this.form}>
              <div className={`u-height--only-lg--20`} />
              <div className={`u-height--lg--20`} />
              <BackgroundBtn
                highlighted={true}
                className='u-col-12'
                text={`join the waitlist`}
                type='submit'
              />
            </Form>
          </div>
        </div>
      </div>
    )
  }
}

export default WaitlistPanel
