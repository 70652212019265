import React, { Component } from 'react'
import { Manager, Reference, Popper } from 'react-popper'
import { CSSTransition } from 'react-transition-group'
import styles from './index.module.scss'

class Tooltip extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: props.isOpen
    }
  }
  render() {
    const isOpen = this.props.hidden ? false : this.state.isOpen
    return (
      <div className={`${this.props.className}`}>
        <Manager>
          <Reference>
            {({ ref }) => (
              <div
                style={{ width: '100%', height: '100%' }}
                ref={ref}
                onMouseEnter={() => this.setState({ isOpen: true })}
                onMouseLeave={() => this.setState({ isOpen: false })}
              >{ this.props.reference }</div>
            )}
          </Reference>
          <Popper placement={this.props.placement}>
            {({ ref, style, placement, arrowProps, scheduleUpdate }) => {
              this.scheduleUpdate = scheduleUpdate
              return (
                <CSSTransition
                  in={isOpen}
                  unmountOnExit
                  timeout={300}
                  onEnter={this.scheduleUpdate}
                  onEntering={this.scheduleUpdate}
                  classNames={{
                    enter: styles.tooltipEnter,
                    enterActive: styles.tooltipEnterActive,
                    enterDone: styles.tooltipEnterDone,
                    exit: styles.tooltipExit,
                    exitActive: styles.tooltipExitActive,
                    exitDone: styles.tooltipExitDone
                  }}
                >
                  <div ref={ref} style={style} data-placement={placement}>
                    <div data-placement={placement} className={`${styles.tooltip} t-paragraph-0 t-bold`}>
                      { this.props.popper }
                      <div ref={arrowProps.ref} style={arrowProps.style} data-placement={placement} className={styles.tooltipArrow} />
                    </div>
                  </div>
                </CSSTransition>
              )
            }}
          </Popper>
        </Manager>
      </div>
    )
  }
}

Tooltip.defaultProps = {
  isOpen: false,
  placement: 'bottom'
}

export default Tooltip
