import React from 'react'
import Link from 'Components/Utils/Link'
import AppConstants from 'AppConstants'
import styles from './index.module.scss'

const MiniProposedProducts = (props) => {
  return (
    <div>
      {
        props.items.slice(0, props.numOfItems).map(item => {
          return (
            <Link key={`proposed-products-${item.id}`} to={`/${item.permalink}`} className={`u-row ${styles.proposedProductContainer}`}>
              <img alt={`It's a product photo of a proposed product named ${item.title} in ${item.color.name} color`} src={`${AppConstants.CLOUDFRONT_CONTENT_URL}${item.media.rect[0].srcSmall}`} />
              <p className={`t-title-1 u-uppercase`}>{`${item.title} in ${item.color.name}`}</p>
            </Link>
          )
        })
      }
    </div>
  )
}

export default MiniProposedProducts
