import React, { Component } from 'react'
import { graphql, navigate } from 'gatsby'
import assign from 'object-assign'
import { WARNING } from 'Components/UI/Notifications'
import Analytics from 'Components/Utils/Analytics'
import WindowStore from 'WindowStore'
import AppStore from 'AppStore'
import AppConstants from 'AppConstants'
import AppActions from 'AppActions'
import Layout from 'Components/Layout'
import SeoHead from 'Components/SeoHead'
import Img from 'Components/UI/Img'
import Breadcrumbs from 'Components/UI/Breadcrumbs'
import BackgroundBtn from 'Components/UI/BackgroundBtn'
// import PrimaryBtn from 'Components/UI/PrimaryBtn'
import Accordion from 'Components/UI/Accordion'
import ProductsSlideshow from 'Components/UI/ProductsSlideshow'
import MiniProposedProducts from 'Components/UI/MiniProposedProducts'
import ScrollingMessageWithIcon from 'Components/UI/ScrollingMessageWithIcon'
import SizesSelector from 'Components/UI/SizesSelector'
import Link from 'Components/Utils/Link'
import WaitlistPanel from 'Components/UI/WaitlistPanel'
import FullscreenSlideshow from './FullscreenSlideshow'
import SizeGuideLayer from 'Components/UI/SizeGuideLayer'
import { CSSTransition } from 'react-transition-group'
import LazyLoad from 'react-lazyload'
import { forceCheck } from 'react-lazyload'
import { cancelablePromise, productSizes, productSides, getAvailabilityTextByID, getAvailabilityColorByID } from 'Components/Utils/helpers'
import Price from 'Components/Utils/FormatPrice'
import styles from './index.module.scss'
require('@gouch/to-title-case')

class ProductPage extends Component {
  constructor(props) {
    super(props)
    this.currentPathname = this.props.location.pathname
    this.parent = React.createRef()
    this.sidePanel = React.createRef()
    this.colorVariationsWrapper = React.createRef()
    this.sizeGuideLayer = React.createRef()
    this.mobileButton = React.createRef()
    this.hero = React.createRef()
    this.sidePanelIsBlocked = false
    this.imageHeight = 500
    const data = this.setupData()
    this.texts = {
      addToBag: 'Add to Bag',
      addToBagMobile: 'Added to Bag',
      chooseYourSize: 'Choose your size'
    }
    const { availability_status } = this.props.data.productsJson
    const status = availability_status
    const statusMessage = getAvailabilityTextByID(status)
    const statusColor = getAvailabilityColorByID(status)
    this.state = {
      isHidden: status === 'hidden' || status === 'sold_out' || status === 'coming_soon',
      statusMessage,
      statusColor,
      fullscreenSlideshowIsOpen: false,
      isProductInFavorites: AppStore.isProductInFavorites(this.props.data.productsJson.id),
      viewType: undefined,
      relatedProducts: undefined,
      recentlyViewedProducts: undefined,
      availableSizes: productSizes,
      showWaitlistSizeErrorMessage: false,
      selectedSize: undefined,
      isCustomer: AppStore.userIsValid(AppStore.getCustomer()),
      waitlistIsLoading: false,
      waitlistSelectedSize: undefined,
      bagButtonText: this.texts.addToBag,
      ...data
    }
  }
  componentDidMount() {
    this.fetchData()
    this.fetchRecentlyViewed()
    this.addToRecentlyViewed()
    forceCheck()
    AppStore.on(AppConstants.ADD_TO_FAVORITES, this.updateFavorite)
    AppStore.on(AppConstants.ADD_TO_BAG, this.resetAddToBackState)
    AppStore.on(AppConstants.REMOVE_FROM_FAVORITES, this.updateFavorite)
    AppStore.on(AppConstants.EXPAND_HEADER, this.onExpandHeader)
    AppStore.on(AppConstants.COLLAPSE_HEADER, this.onCollapseHeader)
    AppStore.on(AppConstants.SUBSCRIBE_TO_WAITLIST, this.onSubscribeToWaitlist)
    AppStore.on(AppConstants.CUSTOMER_STATUS_CHANGED, this.checkCustomer)
    window.addEventListener('resize', this.resize)
    setTimeout(this.resize)
  }
  componentDidUpdate() {
    if (this.currentPathname !== this.props.location.pathname) {
      const data = this.setupData()
      this.setState({
        ...data
      })
      this.addToRecentlyViewed()
      this.fetchData()
      this.fetchRecentlyViewed()
    }
    this.currentPathname = this.props.location.pathname
  }
  componentWillUnmount() {
    if (this.productSizesFetch) this.productSizesFetch.cancel()
    if (this.relatedProductsFetch) this.relatedProductsFetch.cancel()
    AppStore.off(AppConstants.ADD_TO_FAVORITES, this.updateFavorite)
    AppStore.off(AppConstants.ADD_TO_BAG, this.resetAddToBackState)
    AppStore.off(AppConstants.REMOVE_FROM_FAVORITES, this.updateFavorite)
    AppStore.off(AppConstants.EXPAND_HEADER, this.onExpandHeader)
    AppStore.off(AppConstants.COLLAPSE_HEADER, this.onCollapseHeader)
    AppStore.off(AppConstants.SUBSCRIBE_TO_WAITLIST, this.onSubscribeToWaitlist)
    AppStore.off(AppConstants.CUSTOMER_STATUS_CHANGED, this.checkCustomer)
    window.removeEventListener('resize', this.resize)
  }
  onSubscribeToWaitlist = (e) => {
    if (e && e.state === 'loading') this.setState({ waitlistIsLoading: true }, () => { setTimeout(AppActions.openLockedMessagesPanel, 0, this.waitlistMessageDom(), 'light') })
    else this.setState({ waitlistIsLoading: false }, () => { 
      setTimeout(AppActions.openLockedMessagesPanel, 0, this.waitlistMessageDom(), 'light')
      setTimeout(this.closeWaitlistMessagePanel, 0)
    })
  }
  waitlistMessageDom = () => {
    this.waitlistPanel = React.createRef()
    return (
      <WaitlistPanel
        ref={this.waitlistPanel}
        isLoading={this.state.waitlistIsLoading}
        availableSizes={this.state.availableSizes}
        waitlistSelectedSize={this.state.waitlistSelectedSize}
        onSizeWaitlistClick={this.onSizeWaitlistClick}
        onSizeGuideClick={this.onSizeGuideClick}
        showWaitlistSizeErrorMessage={this.state.showWaitlistSizeErrorMessage}
        onWaitlistFormSubmit={this.onWaitlistFormSubmit}
        closeWaitlistMessagePanel={this.closeWaitlistMessagePanel}
      />
    )
  }
  closeWaitlistMessagePanel = () => {
    setTimeout(AppActions.closeLockedMessagesPanel, 0)
    this.setState({ showWaitlistSizeErrorMessage: false, waitlistSelectedSize: undefined })
  }
  onWaitlistFormSubmit = () => {
    if (this.state.waitlistSelectedSize) {
      const email = this.waitlistPanel.current.form.current.refs.mail.state.value
      const size = this.state.waitlistSelectedSize
      const { title, sku, style_id } = this.props.data.productsJson
      setTimeout(AppActions.subscribeToWaitlist, 0, email, size, title, sku, style_id)
    } else {
      this.setState({ showWaitlistSizeErrorMessage: true }, () => {
        setTimeout(AppActions.openLockedMessagesPanel, 0, this.waitlistMessageDom(), 'light')
      })
    }
  }
  onExpandHeader = () => {
    if (this.isMobile) return
    this.sidePanel.current.classList.remove(styles.productInfosExpand)
  }
  onCollapseHeader = () => {
    if (this.isMobile) return
    this.sidePanel.current.classList.add(styles.productInfosExpand)
  }
  checkCustomer = () => {
    this.setState({ isCustomer: AppStore.userIsValid(AppStore.getCustomer()) })
  }
  setupData() {
    const data = this.props.data.productsJson
    const productImages = data.media.wide
    const productImagesRect = data.media.rect
    const season = this.props.pageContext.season
    const collection = this.props.pageContext.collection
    const breadcrumbsData = [
      {
        path: `/category/${season.slug}`,
        title: season.name
      },
      {
        path: `/${season.slug}/${collection.slug}`,
        title: collection.name,
        disable: true
      }
    ]
    return {
      productImagesRect,
      productImages,
      season,
      collection,
      breadcrumbsData
    }
  }
  resize = () => {
    const windowH = WindowStore.Size.h
    const viewType = WindowStore.getViewType()
    if (viewType !== AppConstants.DESKTOP) this.setHeroLimit()
    if (viewType !== this.state.viewType) {
      this.setState({
        viewType
      })
    }
    if (viewType === AppConstants.DESKTOP) {
      const sidePanelH = this.sidePanel.current.getBoundingClientRect().height
      this.sidePanel.current.style.top = `${(windowH / 2) - (sidePanelH * 0.46)}px`
    }
  }
  updateFavorite = () => {
    this.setState({ isProductInFavorites: AppStore.isProductInFavorites(this.props.data.productsJson.id) })
  }
  setHeroLimit() {
    if (this.hero.current) this.heroLimit = (this.hero.current.offsetHeight - WindowStore.Size.h) + this.hero.current.offsetTop + 50 + 52
  }
  fetchRecentlyViewed() {
    const recentProducts = []
    const recentlyViewedProducts = AppStore.getRecentlyViewed()
    recentlyViewedProducts.forEach(item => recentProducts.push(item.product))
    this.setState({ recentlyViewedProducts: recentProducts }, this.resize)
  }
  fetchData() {
    if (this.productSizesFetch) this.productSizesFetch.cancel()
    if (this.relatedProductsFetch) this.relatedProductsFetch.cancel()
    const { id, color, title, heel_height, material, taxonomy, other_colors } = this.props.data.productsJson
    const taxonom =  taxonomy.map((tax) => {
      const sub = tax.subcategories.map((sub) => {
        return sub.name
      })
      return [].concat.apply([], sub)
    })
    const tags = [ color.name, title, heel_height.name, material.name, [].concat.apply([], taxonom) ]
    const flattenTags = [].concat.apply([], tags)
    const stringifyTags = flattenTags.join(',')
    const notToInclude = other_colors.filter(item => item.availability_status === 'hidden').map(other => {
      return other.id
    })
    notToInclude.push(id)
    const notInclude = notToInclude.join(',')
    this.productSizesFetch = cancelablePromise(fetch(`${AppConstants.API_URL}available-product-sizes?id=${id}`))
    this.productSizesFetch.promise
    .then((res) => res.json())
    .then((availableSizes) => {
      setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
      const stock = availableSizes[0].sizes
      const inStock = stock.filter(item => item.in_stock === true).length === 0 ? false : true
      if (inStock) {
        this.setState({ availableSizes: stock }, this.resize)
      } else {
        const status = 'coming_soon'
        const statusMessage = getAvailabilityTextByID(status)
        const statusColor = getAvailabilityColorByID(status)
        this.setState({
            availableSizes: stock,
            isHidden: true,
            statusMessage,
            statusColor
          }, this.resize)
      }
    })
    .catch(e => { setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT) })
    this.relatedProductsFetch = cancelablePromise(fetch(`${AppConstants.API_URL}related-products?tags=${stringifyTags}&not=${notInclude}`))
    this.relatedProductsFetch.promise
    .then((res) => res.json())
    .then((relatedProducts) => {
      const products = []
      relatedProducts.forEach(item => products.push(item))
      this.setState({ relatedProducts: products }, this.resize)
    })
    .catch(e => {})
  }
  addToRecentlyViewed() {
    const product = this.props.data.productsJson
    setTimeout(AppActions.addToRecentlyViewed, 0, {product})
  }
  onFavClick = () => {
    const product = this.props.data.productsJson
    setTimeout(AppActions.addToFavorites, 0, { product })
  }
  removeFromFavorites = () => {
    const product = this.props.data.productsJson
    setTimeout(AppActions.removeFromFavorites, 0, { product })
  }
  resetAddToBackState = () => {
    navigate('/bag')
  }
  onSizeClick = (size) => {
    this.setState({ selectedSize: size, bagButtonText: `${this.texts.addToBag} – Size ${size.name}` })
  }
  onSizeWaitlistClick = (size) => {
    this.setState({ waitlistSelectedSize: size, showWaitlistSizeErrorMessage: false}, () => {
      setTimeout(AppActions.openLockedMessagesPanel, 0, this.waitlistMessageDom(), 'light')
    })
  }
  onAccordionClick = () => {
    this.resize()
  }
  sendToBag = (currentSize) => {
    const size = currentSize ? currentSize : this.state.selectedSize
    const product = this.props.data.productsJson
    const data = assign({product}, {size})
    setTimeout(AppActions.addToBag, 0, data)
  }
  addToBag = () => {
    if (this.state.selectedSize) this.sendToBag(this.state.selectedSize)
    else setTimeout(AppActions.openNotification, 0, { type: WARNING, message: `Please select your size before` })
  }
  launchWaitlist = () => {
    const { title, sku, style_id } = this.props.data.productsJson
    Analytics.send('waitlist', 'launch', { product: { title, sku, style_id } })
    setTimeout(AppActions.openLockedMessagesPanel, 0, this.waitlistMessageDom(), 'light')
  }
  onSingleImageClick = () => {
    this.setState({ fullscreenSlideshowIsOpen: true })
  }
  onCloseFullscreenSlideshow = () => {
    this.setState({ fullscreenSlideshowIsOpen: false })
  }
  onSizeGuideClick = () => {
    this.sizeGuideLayer.current.toggle()
  }
  onFirstImageLoad = (data) => {
    this.imageHeight = data.height
    data.el.classList.add('u-fade-appear-active')
  }
  render() {
    const isCustomer = this.state.isCustomer
    const isPrivateSale = this.props.data.dataJson.PRIVATE_SALE
    const lockSale = !isCustomer && isPrivateSale
    this.isMobile = this.state.viewType === AppConstants.MOBILE || this.state.viewType === AppConstants.TABLET
    const { title, price, other_colors, description, short_description, sku, style_id, sale, color } = this.props.data.productsJson
    const { productImages, collection, breadcrumbsData, isProductInFavorites } = this.state
    const detailsSplit = short_description.split(', ')
    const showSale = sale.inSale && !lockSale
    const saison = sku.substring(0, 4)
    const fullDescription = (
      <div>
        <div dangerouslySetInnerHTML={{__html: description}} />
        <div className='u-height--20' />
        <ul>
          {
          detailsSplit.map((detail, index) => {
            return (
              <li key={`product-details---${index}`}><div className={`${styles.dot} u-inline-block`}/>{detail.trim()}</li>
            )
          })
          }
        </ul>
        <div className='u-height--20' />
        <p>{`SKU Number: ${sku}`}</p>
        <p>{`Style ID: ${style_id}`}</p>
      </div>
    )
    const shippingInfo = <div>Across the globe, we offer free express shipping with all orders. All orders are processed Monday through Friday excluding holidays.</div>
    const returnsAndExchanges = <div>For online purchases, Stella Luna will refund the purchase price of merchandise that is returned in its original condition, with unscratched soles, and accompanied by the original invoice, original Stella Luna packaging and security return/exchange label intact and attached to the item. Customized products cannot be returned. <br/><br/>You must inform Stella Luna of your intention to return the merchandise within 14 days of the date of delivery by directly returning the merchandise to Stella Luna: <br/><br/>MPO Futurlog / Stella Luna<br/>6228 Boulevard de l'Europe,<br/>53700 Villaines-la-Juhel<br/><br/>Shoes must be returned with the original invoice as well as a written and signed explanation of the reason for the return.</div>
    const sizesSelector = (
      <div className={`${styles.sizeSelectorContainer}`}>
        <SizesSelector
          availableSizes={this.state.availableSizes}
          selectedSize={this.state.selectedSize}
          onSizeClick={this.onSizeClick}
          onClickSizeGuideBtn={this.onSizeGuideClick}
        />
      </div>
    )
    const colorInformation = (
      <div className={`${styles.colorInformation}`}>
        <div className={`${styles.colorVariantSide}`} style={{ backgroundColor: color.code }} />
        <p className='t-paragraph-0'>{`In ${color.name.toTitleCase()}`}</p>
      </div>
    )
    const addButtons = (
      <div className={`u-relative u-fit-w`}>
        <BackgroundBtn
          text={this.state.bagButtonText}
          highlighted
          className={`${styles.bagButton} u-fit-w`}
          onClick={this.addToBag}
          icon={{id: `bag`, className: `t-fill-color-white`, style: {width: 16, height: 20, top: -1, marginLeft: 10}}}
          fullBlack
        />
        <BackgroundBtn
          text={isProductInFavorites ? `Remove from Favorites` : `Add to Favorites`}
          highlighted
          className={`u-fit-w`}
          onClick={isProductInFavorites ? this.removeFromFavorites : this.onFavClick}
          icon={{id: `heart`, className: `t-stroke-color-black t-fill-color-white`, style: {width: 16, height: 15, top: 0, marginLeft: 10}}}
          fullBlack
          negative
        />
      </div>
    )
    const colorVariants = (
      <div ref={this.colorVariationsWrapper} >
        <p className='t-paragraph-2'>Other Styles</p>
        <div>
          {
            other_colors.filter(item => item.availability_status !== 'hidden').map((variant, i) => {
              return (
                <Link to={`/${variant.permalink}`} className={`${styles.variantImage} u-inline-block`} key={`color-variant-${variant.id}-${i}`}>
                  <Img
                    baseUrl={AppConstants.CLOUDFRONT_CONTENT_URL}
                    src={variant.media.rect[0].srcSmall}
                    srcRetina={variant.media.rect[0].src}
                    noRetinaOnMobile={true}
                    alt={`Alternative color of ${title} from ${collection.name} ${saison} collection in ${variant.color.name} color`}
                  />
                </Link>
              )
            })
          }
        </div>
      </div>
    )
    const infos = (
      <div className={`u-offset-lg-1 u-col-lg-5 u-pad-x--lg`}>
        <Accordion title={`Description & Details`} open={true} borderColor={'t-border-color-bb-gray'}>{fullDescription}</Accordion>
        <div className={`u-height--lg--20`} />
        <div className={`u-height--only-lg--20`} />
        <Accordion title={`Shipping Info`} open={true} borderColor={'t-border-color-bb-gray'}>{shippingInfo}</Accordion>
        <div className={`u-height--lg--20`} />
        <div className={`u-height--only-lg--20`} />
        <Accordion title={`Returns & Exchanges`} open={false} borderColor={'t-border-color-bb-gray'}>{returnsAndExchanges}</Accordion>
      </div>
    )
    const status = (
      <div className={`u-row ${styles.statusContainer}`}>
        <p className='t-title-1 u-inline-block u-uppercase u-pad-r--10'>{`${this.state.statusMessage.toTitleCase()}`}</p>
        { !this.state.isHidden && <span className={`t-paragraph-0 t-color-gray`}>Your selection is available for immediate, free shipping</span> }
      </div>
    )
    const maillist = (
      <div>
        <CSSTransition
          timeout={400}
          in={true}
          appear={true}
          enter={false}
          exit={false}
          classNames={{
            appear: 'u-fade-appear',
            appearActive: 'u-fade-appear-active--simple'
          }}
        >
          <div>
            <div className={`u-height--lg--20`} />
            <div className={`u-height--only-lg--10`} />
            <BackgroundBtn
              text={`join the waitlist`}
              highlighted
              className={`u-fit-w`}
              onClick={this.launchWaitlist}
              icon={{id: `email-send`, className: `t-fill-color-white`, style: {width: 22, height: 22, top: -1, marginLeft: 10}}}
              fullBlack
            />
          </div>
        </CSSTransition>
      </div>
    )
    const miniProposedProducts = (
      <div>
        { this.state.relatedProducts && this.state.relatedProducts.length > 0 &&
        <CSSTransition
          timeout={400}
          in={true}
          appear={true}
          enter={false}
          exit={false}
          classNames={{
            appear: 'u-fade-appear',
            appearActive: 'u-fade-appear-active--simple'
          }}
        >
          <div>
            <div className={`u-height--lg--20`} />
            <div className={`u-height--only-lg--20`} />
            <h2 className='t-title-1 u-inline-block u-uppercase'>You may also like</h2>
            <div className={`u-height--lg--10`} />
            <div className={`u-height--only-lg--10`} />
            <MiniProposedProducts items={this.state.relatedProducts} numOfItems={2} />
          </div>
        </CSSTransition>
      }
      </div>
    )
    const side = (
      <div>
        <div className={`u-height--only-lg--20`} />
        <div ref={this.sidePanel} className={`${styles.productInfos} u-col-lg-4 u-offset-lg-7`}>
          <div>
            <div>
              <h2 className='t-title-1 u-inline-block u-uppercase'>{collection.name}</h2>
            </div>
            <h1 className='t-big-title'>{title}</h1>
            { !this.state.isHidden &&
              <div>
                <div className={`u-height--only-lg--10`} />
                <div className={`u-height--lg--10`} />
                { !showSale && <p className={`${styles.price} t-paragraph-2`}>{Price.format( AppConstants.LOCALE, AppConstants.CURRENCY, price )}</p>}
                { showSale &&
                <p className={`${styles.price} t-paragraph-2`}>
                  <span>
                    <del>
                      <span>{Price.format( AppConstants.LOCALE, AppConstants.CURRENCY, price )}</span>
                    </del>
                    <span className={styles.salePrice}>{Price.format( AppConstants.LOCALE, AppConstants.CURRENCY, sale.price )}</span>
                  </span>
                </p>
                }
              </div>
            }
            <div className={`u-height--lg--20`} />
            <div className={`u-height--only-lg--10`} />
            <div className={``} >{ colorInformation }</div>
            <div className={`u-height--lg--20`} />
            <div className={`u-height--only-lg--10`} />
            { status }
            { this.state.isHidden &&
              <div>
                { maillist }
                { miniProposedProducts }
              </div>
            }
            { !this.state.isHidden &&
              <div>
                <div className={`u-height--lg--10`} />
              { !this.isMobile && sizesSelector }
              { !this.isMobile && addButtons }
              </div>
            }
          </div>
        </div>
      </div>
    )
    return (
      <Layout>
        <SeoHead
          title={`Collection ${collection.name} — ${title} ${color.name.toTitleCase()}`}
          path={this.props.location.pathname}
          description={`${description}`}
          keywords={`${title}, ${collection.name}, ${detailsSplit.map(detail => ` ${detail}`)}`}
        />
        <ScrollingMessageWithIcon/>
        <div ref={this.parent}>
          { !this.isMobile && <Breadcrumbs items={breadcrumbsData}/> }
          <CSSTransition
            in={this.state.fullscreenSlideshowIsOpen}
            unmountOnExit
            timeout={400}
            classNames={{
              enter: styles.fullscreenSlideshowEnter,
              enterActive: styles.fullscreenSlideshowEnterActive,
              enterDone: styles.fullscreenSlideshowEnterDone,
              exit: styles.fullscreenSlideshowExit,
              exitActive: styles.fullscreenSlideshowExitActive,
              exitDone: styles.fullscreenSlideshowExitDone,
            }}>
            <FullscreenSlideshow
              images={productImages}
              onClose={this.onCloseFullscreenSlideshow}
            />
          </CSSTransition>
          <SizeGuideLayer ref={this.sizeGuideLayer} />
          <div className={`u-height--only-lg--20`} />
          { side }
          <div className={`u-height--only-lg--20`} />
          <section ref={this.hero}>
            {/* Single Image */}
            <div className={`${styles.heroImagesContainer} u-col-12 u-col-lg-7`}>
              { productImages.map((image, index) => {
                return (
                  <LazyLoad key={`product-image-${image.src}-${index}`} height={500} offset={50} once>
                    <Img
                      baseUrl={AppConstants.CLOUDFRONT_CONTENT_URL}
                      className={`${styles.singleImage}`}
                      id={index}
                      didLoad={this.onFirstImageLoad}
                      onClick={this.onSingleImageClick}
                      src={image.src}
                      srcRetina={image.srcRetina}
                      noRetinaOnMobile={true}
                      alt={`${productSides[index].name} product photo of ${title} from ${collection.name} ${saison} collection in ${color.name} color`}
                    />
                  </LazyLoad>
                )
              })}
            </div>
            <div className={`u-height--only-lg--20`} />
            { !this.state.isHidden &&
            <div>
              { this.isMobile && sizesSelector }
              { this.isMobile && <div className={`u-pad-x`}>{ addButtons }</div> }
            </div>
            }
            <div className={`u-height--only-lg--20`} />
            <div className={`u-height--120`} />
            { other_colors.length > 0 &&
            <LazyLoad offset={300} once>
              <div className={`u-col-lg-5 u-offset-lg-1 u-pad-x--lg`}>{ colorVariants }</div>
            </LazyLoad>
            }
            <div className={`u-height--20`} />
            { infos }
            <div className={`u-height--60`} />
          </section>
          { (this.state.relatedProducts && this.state.relatedProducts.length > 0) &&
          <section className='u-row u-pad-y--spacing t-border-top-black t-bg-white u-relative u-z-index--6'>
            <p className={`${styles.recommendations} u-col-md-10 u-offset-md-1 t-big-title u-pad-x--md`}>you may also like</p>
            <LazyLoad offset={300} once>
              <ProductsSlideshow className={`u-col-12`} baseUrl={AppConstants.CLOUDFRONT_CONTENT_URL} products={this.state.relatedProducts}/>
            </LazyLoad>
          </section>
          }
          { (this.state.recentlyViewedProducts && this.state.recentlyViewedProducts.length > 0) &&
          <section className='u-row u-pad-y--spacing t-border-top-black t-bg-white u-relative u-z-index--5'>
            <p className={`${styles.recommendations} u-col-md-10 u-offset-md-1 t-big-title u-pad-x--md`}>recently viewed</p>
            <LazyLoad offset={300} once>
            <ProductsSlideshow className={`u-col-12`} baseUrl={AppConstants.CLOUDFRONT_CONTENT_URL} products={this.state.recentlyViewedProducts}/>
            </LazyLoad>
          </section>
          }
        </div>
      </Layout>
    )
  }
}

export default ProductPage

export const pageQuery = graphql`
  fragment productDetails on ProductsJson {
    id,
    availability_status,
    title
    description
    short_description
    details
    sku
    slug
    price
    permalink
    sale {
      inSale
      discount
      originalPrice
      price
    }
    color {
      id
      name
      code
      all
    }
    taxonomy {
      id
      name
      slug
      subcategories {
        id
        name
        slug
      }
    }
    material {
      id
      name
      all
    }
    heel_height {
      id
      name
    }
    media {
      wide {
        src
        srcRetina
      }
      rect {
        src
        srcRetina
        srcSmall
      }
    }
    style_id
  }
  query ProductPage($slug: String!) {
    dataJson {
      PRIVATE_SALE
    }
    productsJson(slug: { eq: $slug }) {
      ...productDetails
      other_colors {
        id
        availability_status
        title
        price
        permalink
        color {
          id
          name
          code
          all
        }
        media {
          rect {
            src
            srcRetina
            srcSmall
          }
        }
      }
    }
  }
  `
