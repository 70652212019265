import React, { Component } from 'react'
import styles from './index.module.scss'
import WindowActions from 'WindowActions'
import SimpleTextIconButton from 'Components/UI/SimpleTextIconButton'
import GuideSize from 'Components/UI/GuideSize'

class SizeGuideLayer extends Component {
  constructor(props) {
    super(props)
    this.parent = React.createRef()
    this.IS_ACTIVE = false
  }
  componentDidMount() {
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
    WindowActions.enableScroll()
  }
  toggle() {
    if (this.IS_ACTIVE) this.close()
    else this.open()
  }
  open = () => {
    this.IS_ACTIVE = true
    WindowActions.disableScroll()
    this.parent.current.classList.add(styles.isActive)
  }
  close = () => {
    this.IS_ACTIVE = false
    WindowActions.enableScroll()
    this.parent.current.classList.remove(styles.isActive)
  }
  render() {
    return (
      <div className={`${styles.parent} size-guide-layer u-fixed u-fit u-pos-tl u-z-index--9982 u-overflow-y-s`} ref={this.parent}>
        <div className="u-col-1 u-offset-10">
          <SimpleTextIconButton className={`${styles.closeButton} u-fixed`} text='Close' svgId='cross-icon' onClick={this.close} />
        </div>
        <div className="u-offset-lg-1 u-col-lg-10 u-absolute">
          <div className='u-height--120' />
          <GuideSize />
          <div className='u-height--120' />
        </div>
        <div onClick={this.close} className={`u-z-index--back u-fit-vwh u-fixed t-bg-white u-pos-tl`}></div>
      </div>
    )
  }
}

export default SizeGuideLayer
